<template>
  <DropdownWithSearch
    class="home-dropdown"
    style="width: 320px; max-width: 320px"
    fixed-popup-width="320px"
    v-if="areProjectsLoaded"
    :items="projectsDropdownOptions"
    :selected-project="selectedProject"
    @select="onSelectProject"
    @change="onChangeSearch"
    placeholder="Select a Project"
  />
  <LoaderWithText v-else style="padding: 8px 16px">
    Projects are loading
  </LoaderWithText>
  <RouterLink :to="`/${selectedProjectId}`" custom v-slot="{ navigate }">
    <EditorButton
      class="initial-screen__button"
      :on-click="navigate"
      :disabled="!selectedProjectId"
    >
      Open
    </EditorButton>
  </RouterLink>
</template>

<script lang="ts">
import EditorButton from "@/components/button/EditorButton.vue";
import { LoaderWithText } from "@cna/common";
import { defineComponent } from "vue";
import DropdownWithSearch from "../components/dropdown/DropdownWithSearch.vue";
import { usePagesDropdownHelper } from "../components/dropdown/usePagesDropdownHelper";

export default defineComponent({
  name: "InitialScreenModal",
  components: {
    DropdownWithSearch,
    EditorButton,
    LoaderWithText,
  },
  setup() {
    const {
      projectsDropdownOptions,
      onChangeSearch,
      currentProject,
      redirectToProject,
      selectedProject,
      onSelectProject,
      selectedProjectId,
      areProjectsLoaded,
    } = usePagesDropdownHelper();

    return {
      selectedProject,
      onSelectProject,
      areProjectsLoaded,
      projectsDropdownOptions,
      onChangeSearch,
      currentProject,
      redirectToProject,
      selectedProjectId,
    };
  },
});
</script>

<style>
.initial-screen__button {
  justify-content: center;
  padding: 8px 16px !important;
  font-size: 14px;
  line-height: 20px;
}
</style>
