import type { AxiosRequestConfig } from "axios";
import type { Result } from "../../../../types";
import type { PathBuilder } from "../../../../utils";
import { client } from "../../axios";

export type Version = {
  id: number;
  description: string;
  restorable: boolean;
  created: string;
  created_by?: string;
};

export type Snapshot = Version & {
  published: boolean;
  previewing: boolean;
  published_by?: string;
  published_preview_by?: string;
  last_published_at?: string;
  last_published_preivew_at?: string;
};

export type GetSnapshotsResult = Result<Snapshot[]>;

export type PublishedDomainData = {
  main: string;
  preview: string;
};

export type GetPublishedDomainsResult = Result<{
  domains: PublishedDomainData;
  suffix: string;
}>;

export type GetPublishedLocalArtifactResult = Result<{
  id: number;
  project_id: number;
  version_id: number;
  system_id: string;
  tar_name: string;
  gsutil_url: string;

  icomera_customer_id: number;
  icomera_account_id: number;
  portal_class: string;
  icomera_realm: string;
  is_default: boolean;
  portal_key: string;
  ipv4_subnet_star_masks: string[];
  health_check_url: string;
}>;

export type PostCreateBody = {
  description: string;
};

export type PostCreateResult = Result<{ id: number }>;

export const versions = (path: PathBuilder) => {
  const get = (config?: AxiosRequestConfig) =>
    client.get<GetSnapshotsResult>(path.value, config);

  const postRestore = (snapshotId: number, config?: AxiosRequestConfig) =>
    client.post<Result<null>>(
      path.slash(snapshotId).slash("restore").value,
      undefined,
      config
    );

  const getPublishedDomains = (config?: AxiosRequestConfig) =>
    client.get<GetPublishedDomainsResult>(
      path.slash("published").slash("domains").value,
      config
    );

  const getPublishedLocalArtifact = (config?: AxiosRequestConfig) =>
    client.get<GetPublishedLocalArtifactResult>(
      path.slash("published").slash("local_artifact").value,
      config
    );

  const postCreate = (body: PostCreateBody, config?: AxiosRequestConfig) =>
    client.post<PostCreateResult>(path.slash("create").value, body, config);
  return {
    get,
    postRestore,
    getPublishedDomains,
    getPublishedLocalArtifact,
    postCreate,
  };
};
